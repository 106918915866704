<template>
  <app-list-view
    server-side
    hideCreate
    app="audit"
    model="auditheader"
    api-url="audit/audit-header/"
    :title="$t('menu.audit')"
    :editTo="{ name: 'auditEdit' }"
    v-model="selected"
    :headers="headers"
  >
    <template v-slot:prefix-actions>
      <v-btn color="primary" @click="openDialogAudit">
        {{ $t('btn.create') }}
      </v-btn>
    </template>

    <!-- Dialog Audit Wizard Pop -->
    <template v-slot:top>
      <v-dialog v-model="dialogAudit" max-width="1500">
        <v-card flat>
          <v-card-text class="pt-4">
            <v-toolbar dense color="primary white--text">
              <v-toolbar-title>
                {{ $t('menu.createAudit') }}
              </v-toolbar-title>
            </v-toolbar>
            <app-form
              app="audit"
              model="auditheader"
              api-url="audit/audit-header/"
              :title="$t('menu.audit')"
              v-model="formData"
              :prepare-data="prepareData"
              :state="formData.state"
              hideSave
              hideCancel
            >
              <template>
                <v-row class="pt-4">
                  <v-col cols="12" lg="6" class="app-input--approve-wrapper">
                    <app-input
                      name="company"
                      rules="required"
                      type="select-server"
                      :label="$t('fields.selectCompany')"
                      :binds="{
                        apiUrl: 'contact/contact/?active=true'
                      }"
                      v-model="formData.contact_id"
                      @input="getItem"
                    />
                  </v-col>
                  <v-col cols="12" lg="6" class="app-input--approve-wrapper">
                    <app-input
                      name="auditPlan"
                      rules="required"
                      type="select-server"
                      :label="$t('fields.selectAuditPlan')"
                      :disabled="!Boolean(formData.contact_id)"
                      :binds="{
                        apiUrl: auditPlanUrl
                      }"
                      v-model="formData.audit_plan_id"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6" class="app-input--approve-wrapper">
                    <app-input
                      name="trialBalance"
                      type="select-server"
                      :label="$t('fields.selectTrialBalance')"
                      :disabled="!Boolean(formData.contact_id)"
                      :binds="{
                        apiUrl: trialBalanceUrl
                      }"
                      v-model="formData.trial_balance_header_id"
                    />
                  </v-col>
                </v-row>
              </template>
            </app-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialogAudit">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="openDialogWarning">
              {{ $t('btn.create') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog Warning Wizard Pop -->
      <v-dialog v-model="dialogWarning" max-width="1000">
        <v-card flat>
          <v-card-text class="pt-4">
            <v-toolbar dense color="primary white--text">
              <v-toolbar-title>
                {{ $t('menu.warning') }}
              </v-toolbar-title>
            </v-toolbar>
            <v-spacer></v-spacer>
            <p class="pt-4">
              10 credit will be chart. Would you like to confirm?
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialogWarning">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="onSubmit">
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </app-list-view>
</template>

<script>
import AppListView from '@components/AppListView'
import AppInput from '@components/AppInput'
import AppForm from '@components/AppForm'
import { mapGetters } from 'vuex'

export default {
  name: 'auditList',
  components: {
    AppListView,
    AppInput,
    AppForm
  },
  data() {
    return {
      dialogAudit: false,
      dialogWarning: false,
      loading: false,
      auditPlanUrl: null,
      trialBalanceUrl: null,
      audit_header_id: {},
      formData: {
        state: 'draft'
      },
      selected: []
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'audit'
    })
  },
  computed: {
    ...mapGetters(['permissions']),
    headers() {
      return [
        {
          text: this.$t('fields.companyName'),
          value: 'contact_id.name'
        },
        { text: this.$t('fields.sectionName'), value: 'name' },
        { text: this.$t('fields.auditYear'), value: 'audit_year' },
        { text: this.$t('fields.period'), value: 'audit_period' },
        {
          text: this.$t('fields.companyType'),
          value: 'company_type_id.name'
        },
        {
          text: this.$t('fields.projectProgress'),
          value: 'project_progress'
        },
        {
          text: this.$t('fields.projectStatus'),
          value: 'project_status'
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    }
  },
  methods: {
    prepareData(data) {
      data.contact_id = data.contact_id.id
      data.audit_plan_id = data.audit_plan_id.id
      return data
    },
    closeDialogAudit() {
      this.formData = {}
      this.dialogAudit = false
    },
    openDialogAudit() {
      this.dialogAudit = true
    },
    closeDialogWarning() {
      this.dialogWarning = false
    },
    openDialogWarning() {
      this.dialogWarning = true
    },
    getItem() {
      this.getAuditPlanItem()
      this.getTrialBalanceItem()
    },
    getAuditPlanItem() {
      var url = ''
      if (
        this.formData.contact_id !== null &&
        this.formData.contact_id !== undefined
      ) {
        url =
          'audit/audit-plan-header/?contact_id=' + this.formData.contact_id.id
      }
      this.auditPlanUrl = url
    },
    getTrialBalanceItem() {
      var url = ''
      if (
        this.formData.contact_id !== null &&
        this.formData.contact_id !== undefined
      ) {
        url =
          'trial-balance/trial-balance-header/?active=true&contact_id=' +
          this.formData.contact_id.id
      }
      this.trialBalanceUrl = url
    },
    onSubmit() {
      this.loading = true
      this.formData.contact_id = this.formData.contact_id.id || null
      this.formData.audit_plan_id = this.formData.audit_plan_id.id || null
      this.formData.trial_balance_header_id =
        this.formData.trial_balance_header_id?.id || null
      this.$api({
        method: 'post',
        url: `audit/audit-header/`,
        hideSuccessAlert: true,
        data: this.formData
      }).then(({ data }) => {
        this.audit_header_id = data.detail
        this.closeDialogAudit()
        this.openDialogWarning()
        this.onEdit(this.audit_header_id)
      })
    },
    onEdit(item) {
      this.$router.push({
        name: 'auditEdit',
        params: { id: item.id }
      })
    }
  }
}
</script>
